import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'

export default {
  computed: {
    hasDropdownLoadedCommonService () {
      return this.$store.state.ExternalUserService.trainingPanel.commonObj.hasDropdownLoaded
    }
  },
  watch: {
    hasDropdownLoadedCommonService: function (newValue) {
      if (!newValue) {
        this.loadCommonDropdown()
      }
    }
  },
  created () {
    this.loadCommonDropdown()
  },
  methods: {
    loadCommonDropdown () {
      RestApi.getData(trainingElearningServiceBaseUrl, 'common-dropdowns', null).then(response => {
        if (response.success) {
          this.$store.dispatch('ExternalUserService/mutateCommonObjTrainingPanel', {
            hasDropdownLoaded: true,
            roomTypeList: response.data.RoomTypeList,
            roomRentList: response.data.roomRentList,
            roomList: response.data.roomList,
            floorList: response.data.floorList,
            trainingTypeList: response.data.trainingTypeList,
            trainingCategoryList: response.data.trainingCategoryList,
            trainingTitleList: response.data.TrainingTitleList,
            courseList: response.data.courseList,
            courseDocumentSetupList: response.data.courseDocumentSetupList,
            trainerEvaluationList: response.data.trainerEvaluationList,
            personalInfoList: response.data.personalInfoList,
            gradingStatus: response.data.gradingStatus,
            faqSetupList: response.data.faqSetupList,
            trainingReportHeadList: response.data.trainingReportHeadList,
            nominatedOrgList: response.data.nominatedOrgList,
            batchList: response.data.batchList,
            evaluationGradingList: response.data.evaluationGradingList
          })
          this.$store.dispatch('ExternalUserService/localizeDropdownTrainingPanel', { value: this.$i18n.locale })
        }
      })
    }
  }
}
